<template>
  <div class="progress-bar">
    <div class="progress-outer">
      <div class="progress-inner">
        <div
          class="progress-bg"
          :style="`width: ${
            progress >= 100 ? 100 : progress
          }%; height: ${strokeWidth}px;`"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      // 当前进度
      type: Number,
      default: 0,
    },
    strokeWidth: {
      // 进度条线的宽度
      type: Number,
      default: 8,
    },
  },
};
</script>
