/**
 * article模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
import {proxyApi} from "../workers/ProxyApi";
import {TaskEventType} from "../workers/WorkerEventType";
import {http} from "../workers/network"; // 根据需求是否导入qs模块

const article = {
    // 用户所有文档(平铺)
    articleList() {
        return axios.get(`${base.baseURL}/api/Article/ArticleList`);
    },
    // 获取子目录
    childrenList(articleId, isLocal = false) {
        return proxyApi.postMessage({
            task:
                (articleId && articleId.startsWith("local-")) || isLocal
                    ? TaskEventType.DOC_TREE_CHILDREN
                    : TaskEventType.CLOUD_TREE_CHILDREN,
            data: {
                id: articleId,
            },
        });
    },
    // 获取文档树(向上溯源)
    articleTree(articleId, isLocal = false) {
        return proxyApi.postMessage({
            task:
                (articleId && articleId.startsWith("local-")) || isLocal
                    ? TaskEventType.DOC_TREE_PARENT
                    : TaskEventType.CLOUD_TREE_PARENT,
            data: {
                articleId,
            },
        });
    },
    // 获取此目录下完整文档树
    fullArticleTree(articleId, isLocal = false) {
        return proxyApi.postMessage({
            task:
                (articleId && articleId.startsWith("local-")) || isLocal
                    ? TaskEventType.DOC_TREE_FULL
                    : TaskEventType.CLOUD_TREE_FULL,
            data: {
                articleId: articleId || "",
            },
        });
    },
    // 内容详情
    articleDetail(articleId) {
        return proxyApi.postMessage({
            task: articleId.startsWith("local-")
                ? TaskEventType.DOC_MD_GET
                : TaskEventType.CLOUD_MD_GET,
            data: {
                articleId,
            },
        });
    },
    // 新增或修改内容目录信息
    addOrUpdateArticleCatalog(params, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal
                ? TaskEventType.DOC_TREE_UPDATE
                : TaskEventType.CLOUD_TREE_UPDATE,
            data: {
                newData: params,
            },
        });
    },
    // 重命名标题
    renameArticle(params) {
        return proxyApi.postMessage({
            task: params.articleId.startsWith("local-")
                ? TaskEventType.DOC_TREE_RENAME
                : TaskEventType.CLOUD_TREE_RENAME,
            data: params,
        });
    },
    // 修改内容
    updateContent(params) {
        return proxyApi.postMessage({
            task: params.articleId.startsWith("local-")
                ? TaskEventType.DOC_MD_UPDATE
                : TaskEventType.CLOUD_MD_UPDATE,
            data: params,
        });
    },
    // 最新文档（仅十条）
    latestArticleList() {
        return axios.get(`${base.baseURL}/api/Article/LatestArticleList`);
    },
    // 删除文档
    removeArticle(docId, s) {
        return proxyApi.postMessage({
            task: docId.startsWith("local-")
                ? TaskEventType.DOC_TREE_DELETE
                : TaskEventType.CLOUD_TREE_DELETE,
            data: {
                docId,
                s
            },
        });
    },
    // 删除文档
    getChildren(docId) {
        return proxyApi.postMessage({
            task: docId.startsWith("local-")
                ? TaskEventType.DOC_GET_CHILDREN
                : TaskEventType.CLOUD_GET_CHILDREN,
            data: {
                id: docId,
            },
        });
    },
    // 批量更新排序
    batchUpdateSort(params) {
        return axios.post(`${base.baseURL}/api/Article/BatchUpdateSort`, params);
    },

    // 用户回收站所有文档(平铺)
    unArticleList(PageNo = 1, PageSize = 20, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal
                ? TaskEventType.DOC_TREE_DELETE_GET
                : TaskEventType.CLOUD_TREE_DELETE_GET,
            data: {
                PageNo,
                PageSize,
            },
        });
    },
    // // 用户回收站所有文档(平铺)
    // unArticleList(PageNo = 1, PageSize = 20) {
    //   return axios.get(
    //     `${base.baseURL}/api/Article/UnArticleList?PageNo=${PageNo}&PageSize=${PageSize}`
    //   );
    // },

    // 还原文档
    activateArtilce(params, isLocal = false) {
        // return axios.post(`${base.baseURL}/api/Article/ActivateArtilce`, params);
        return proxyApi.postMessage({
            task: isLocal
                ? TaskEventType.DOC_TREE_DELETE_RESTORE
                : TaskEventType.CLOUD_TREE_DELETE_RESTORE,
            data: params,
        });
    },
    // 删除回收站文档(永久删除)
    deleteUnative(params, isLocal = false) {
        // return axios.post(`${base.baseURL}/api/Article/DeleteUnative`, params);
        return proxyApi.postMessage({
            task: isLocal
                ? TaskEventType.DOC_TREE_DELETE_FOREVER
                : TaskEventType.CLOUD_TREE_DELETE_FOREVER,
            data: params,
        });
    },
    // 获取历史记录(最多只能取100条)
    historyArticleList(articleId) {
        return proxyApi.postMessage({
            task: articleId.startsWith("local-")
                ? TaskEventType.DOC_GET_HISTORY
                : TaskEventType.CLOUD_GET_HISTORY,
            data: {articleId},
        });
    },
    // 获取历史记录的详情(id:历史记录的id)
    historyArticleDetail(id) {
        return proxyApi.postMessage({
            task: id.startsWith("local-")
                ? TaskEventType.DOC_GET_HISTORY_DETAIL
                : TaskEventType.CLOUD_GET_HISTORY_DETAIL,
            data: {id},
        });
    },
    // 复原文档
    historyRecoverArticle(id, articleId) {
        return proxyApi.postMessage({
            task: id.startsWith("local-")
                ? TaskEventType.DOC_GET_HISTORY_RECOVER
                : TaskEventType.CLOUD_GET_HISTORY_RECOVER,
            data: {id, articleId},
        });
    },
    //更新文档图标
    updateIconType(params, isLocal = false) {
        return proxyApi.postMessage({
            task:
                (params.articleId && params.articleId.startsWith("local-")) || isLocal
                    ? TaskEventType.DOC_TREE_TYPE
                    : TaskEventType.CLOUD_TREE_TYPE,
            data: params,
        });
    },

    // 引用列表
    TipList() {
        return proxyApi.postMessage({
            task: TaskEventType.CONFIG_GET_FONT_LIST,
        });
    },

    // 保存引用信息
    saveTip(params) {
        return proxyApi.postMessage({
            task: TaskEventType.CONFIG_UPDATE_FONT_LIST,
            data: params,
        });
    },

    // 移除 引用tip
    removeTip(id) {
        return proxyApi.postMessage({
            task: TaskEventType.CONFIG_REMOVE_FONT_LIST,
            data: {id},
        });
    },

    // 复制文档
    cloneArticle(params, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal
                ? TaskEventType.DOC_TREE_CLONE
                : TaskEventType.CLOUD_TREE_CLONE,
            data: params,
        });
    },
    // 搜索标题
    search(keyword, pageNo, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal
                ? TaskEventType.DOC_TREE_SEARCH
                : TaskEventType.CLOUD_TREE_SEARCH,
            data: {
                keyword,
                pageNo,
            },
        });
    },

    //add
    expand(articleId, isExpand) {
        return proxyApi.postMessage({
            task: articleId.startsWith("local-")
                ? TaskEventType.DOC_TREE_EXPAND
                : TaskEventType.CLOUD_TREE_EXPAND,
            data: {
                articleId,
                expand: isExpand,
            },
        });
    },
    simpleArticleInfo(ids, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal
                ? TaskEventType.DOC_TREE_NODES
                : TaskEventType.CLOUD_TREE_NODES,
            data: {
                ids,
            },
        });
    },
    loadAll() {
        return proxyApi.postMessage({
            task: "cloud.get.all",
        });
    },
    clearList() {
        return proxyApi.postMessage({
            task: "cloud.clear.list",
        });
    },

    getAllLink() {
        return proxyApi.postMessage({
            task: "cloud.get.links",
        });
    },
    removeLink(params) {
        return proxyApi.postMessage({
            task: "cloud.remove.link",
            data: params,
        });
    },
    addLink(params) {
        return proxyApi.postMessage({
            task: "cloud.add.link",
            data: params,
        });
    },
    recycleTree() {
        return proxyApi.postMessage({
            task: "cloud.recycle.tree",
        });
    },
    recycleDelete(ids) {
        return proxyApi.postMessage({
            task: "cloud.recycle.delete",
            data: {
                ids
            }
        });
    },
    recycleRecover(data) {
        return proxyApi.postMessage({
            task: "cloud.recycle.recover",
            data
        });
    },
    recycleAppend(data) {
        return proxyApi.postMessage({
            task: "cloud.recycle.append",
            data
        });
    },
    recycleAdd(data) {
        return proxyApi.postMessage({
            task: "cloud.recycle.add",
            data
        });
    },
    updateMarkIcon(data) {
        return proxyApi.postMessage({
            task: "cloud.tree.market",
            data
        });
    },
    updateExts(data) {
        return proxyApi.postMessage({
            task: "cloud.update.exts",
            data
        });
    }
};
export default article;
