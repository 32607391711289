import CryptoJS from 'crypto-js';
export const stringToMD5 = (data) => {
    return CryptoJS.MD5(data).toString().substring(8,24)
}

export const formatDateTime = function (date) {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    let second = date.getSeconds();
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};


export function blobConstruct(blobParts, mimeType = '') {
    if (!Array.isArray(blobParts)) {
        blobParts = [blobParts];
    }

    let blob;
    try {
        blob = new Blob(blobParts, {type: mimeType});
    } catch (e) {
        // @ts-ignore
        let bb = new BlobBuilder;
        blobParts.forEach((blobPart) => {
            bb.append(blobPart);
        });
        blob = bb.getBlob(mimeType);
    }
    return blob;
}

export function fileToBlob(file) {
    if (file.constructor === File) {
        return Promise.resolve(new Blob([file], {type: file.type}));
    } else if (file.constructor === Blob) {
        return Promise.resolve(file);
    }
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            let base64 = e.target.result;
            let blob = base64ToBlob(base64);
            resolve(blob);
        }
    })
}
export function blobToBase64(blob) { //blob转base64
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function (e) {
            resolve(e.target.result);
        }
        reader.readAsDataURL(blob);
    })
}

export function base64ToBlob(base64Data) {
    if (!base64Data) {
        return;
    }
    let arr = base64Data.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bytes = atob(base64Data.split(',')[1]);        //去掉url的头，并转换为byte

    //处理异常,将ascii码小于0的转换为大于0
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], {type: mime});
}

export function blobToFile(blob, name) {
    return new File([blob], name, {type: blob.type});
}

export function blobToText(blob) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.addEventListener("load", (e) => {
            let text = e.target.result;
            resolve(text);
        })
        reader.readAsText(blob)
    })
}

export function sendMessage(data) {
    postMessage(data);
}
