/**
 * insight模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const insight = {
    // 获取列表
    list(pageNo, pageSize) {
        return axios.get(`${base.baseURL}/api/Insight/List?PageNo=${pageNo}&PageSize=${pageSize}`);
    },
    // 保存修改
    save(params) {
        return axios.post(`${base.baseURL}/api/Insight/Save`, params);
    },
    // 移除
    remove(id) {
        return axios.post(`${base.baseURL}/api/Insight/Remove/${id}`);
    },

};

// import { proxyApi } from "../workers/ProxyApi";
// import { TaskEventType } from "../workers/WorkerEventType";
// import { http } from "../workers/network"; // 根据需求是否导入qs模块
// const insight = {
//     // 获取列表
//     list(isLocal = false) {
//         return proxyApi.postMessage({
//             task: isLocal ? TaskEventType.DOC_INSIGHT_LIST : TaskEventType.CLOUD_INSIGHT_LIST,
//             data: params
//         })
//     },
//     // 保存修改
//     save(params, isLocal = false) {
//         return proxyApi.postMessage({
//             task: isLocal ? TaskEventType.DOC_INSIGHT_SAVE : TaskEventType.CLOUD_INSIGHT_SAVE,
//             data: params
//         })
//     },
//     // 移除
//     remove(id, isLocal = false) {
//         return proxyApi.postMessage({
//             task: isLocal ? TaskEventType.DOC_INSIGHT_REMOVE : TaskEventType.CLOUD_INSIGHT_REMOVE,
//             data: {
//                 id
//             }
//         })
//     },

// };

export default insight;
