import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

const index = () => import("@/views/pc/index");
const login = () => import("@/views/pc/login");
const article = () => import("@/views/pc/article");
const articleShare = () => import("@/views/pc/article/share/article");
const paragraphShare = () => import("@/views/pc/article/share/paragraph");
// const article = () => import("@/views/pc/article/oldindex.vue")
const download = () => import("@/views/pc/index/download.vue");
const show = () => import("@/views/pc/index/show.vue");
const about = () => import("@/views/pc/index/about.vue");
const privacy = () => import("@/views/pc/index/privacy.vue");
const agreement = () => import("@/views/pc/index/agreement.vue");
const version = () => import("@/views/pc/index/version.vue");
const wechat = () => import("@/views/pc/login/wechat.vue");
const help = () => import("@/views/pc/index/help.vue");
const refresh = () => import("@/views/pc/refresh");
// const articleold = () => import("@/views/pc/article/indexold.vue")
// const oldtonew = () => import("@/views/pc/article/oldtonew.vue")
// 删除用户信息（测试用，生产环境禁开）
// const logoff = () => import("@/views/pc/account/logoff.vue")
const logList = () => import("@/views/pc/account/logList.vue");
const IconPage = () => import("../views/test/IconPage");

// const mindChat = () => import("../views/test/mindChat.vue");
import store from "../store";
import { proxyApi } from "../workers/ProxyApi"; // 导入vuex文件
const routes = [
  { path: "/", redirect: "/index" },
  {
    path: "/icons",
    name: "icons",
    component: IconPage,
  },
  {
    path: "/index",
    name: "index",
    component: index,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/article",
    name: "articles",
    component: article,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/article/:articleId",
    name: "article",
    component: article,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/article/:articleId/:transfer",
    name: "transferFull",
    component: article,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/download",
    name: "download",
    component: download,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/show",
    name: "show",
    component: show,
  },
  {
    path: "/about",
    name: "about",
    component: about,
  },
  {
    path: "/refresh",
    name: "refresh",
    component: refresh,
  },
  {
    path: "/privacy",
    name: "privacys",
    component: privacy,
  },
  {
    path: "/agreement",
    name: "agreements",
    component: agreement,
  },
  {
    path: "/version",
    name: "versions",
    component: version,
  },
  {
    path: "/privacy/:theme",
    name: "privacy",
    component: privacy,
  },
  {
    path: "/agreement/:theme",
    name: "agreement",
    component: agreement,
  },
  {
    path: "/version/:theme",
    name: "version",
    component: version,
  },
  {
    path: "/help",
    name: "helps",
    component: help,
  },
  {
    path: "/help",
    name: "help",
    component: help,
  },
  {
    path: "/wechat",
    name: "wechat",
    component: wechat,
  },
  {
    path: "/share/article/:articleId",
    name: "articleshare",
    component: articleShare,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/share/paragraph/:articleId",
    name: "paragraphshare",
    component: paragraphShare,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/admin/logList",
    name: "logList",
    component: logList,
  },

  // {
  //   path: "/mindChat",
  //   name: "mindChat",
  //   component: mindChat,
  // },

  // {
  //   path: "/articleold",
  //   name: "articleolds",
  //   component: articleold
  // },
  // {
  //   path: "/articleold/:articleId",
  //   name: "articleold",
  //   component: articleold
  // },
  // {
  //   path: "/oldtonew/:pageNo/:pageSize/:size",
  //   name: "oldtonew",
  //   component: oldtonew
  // },
  // {
  //   path: "/hxciuyvewrxcv5sdfw/logoff",
  //   name: "logoff",
  //   component: logoff
  // },
];

export const router = createRouter({
  history:
    process.env.VUE_APP_PLATFORM === "web"
      ? createWebHistory()
      : createWebHashHistory(),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.requireAuth) {
    if (store.state.isLogin) {
      next();
    } else {
      localStorage.setItem("preRoute", to.path);
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});
