<template>
  <el-dialog
      v-model="dialogVisible"
      :title="title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="380px"
      @closed="()=>{$emit('close')}"
      :show-close="!onlyConfirm"
  >
      <span :style="{color: color}">
        {{ subtitle }}
      </span>
    <template #footer>
        <span class="dialog-footer">
          <el-button v-if="!onlyConfirm"
              :type="curButton===0? 'primary':''"
              @click="dialogVisible = false">
            {{ cancelText }}
          </el-button>
          <el-button
              :type="curButton===1? 'primary':''"
              :disabled="submitState === 1"
              @click="onConfirm"
          >
            {{ submitState === 1 ? submittingText || confirmText : confirmText }}
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "Confirm",
  props: {
    onlyConfirm: {type: Boolean, default: false},
    title: {type: String, default: ''},
    subtitle: {type: String, default: ''},
    confirmText: {type: String, default: '确认'},
    cancelText: {type: String, default: '取消'},
    submittingText: {type: String, default: ''},
    confirm: {type: Function, default: undefined},
    color: {type: String, default: ''},
  },
  data() {
    return {
      dialogVisible: true,
      // 0 normal 1 submitting
      submitState: 0,
      curButton: 1
    }
  },
  mounted() {
    //快捷键
    document.addEventListener("keydown", this.onKeydown);
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    onKeydown(e) {
        if (e.key === 'ArrowLeft') {
          e.stopPropagation();
          this.curButton -= 1;
          this.curButton = this.curButton<0? 1:this.curButton;
        } else if (e.key === 'ArrowRight') {
          e.stopPropagation();
          this.curButton += 1;
          this.curButton = this.curButton>1? 0:this.curButton;
        } else if (e.key === 'Enter') {
          e.stopPropagation();
          if (this.curButton === 1) {
            this.onConfirm()
          } else {
            this.dialogVisible = false;
          }
        }
    },
    async onConfirm() {
      if (this.submitState === 1) return;
      this.submitState = 1;
      if (this.confirm) {
        try {
          await this.confirm();
          this.dialogVisible = false;
        }catch (e) {
          this.submitState = 0;
        }
      }
      this.submitState = 0;
    }
  }
}
</script>

<style scoped>

</style>
