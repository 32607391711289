/**
 * config模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http";
import { proxyApi } from "../workers/ProxyApi";
import { TaskEventType } from "../workers/WorkerEventType"; // 导入http中创建的axios实例

const config = {
  // 保存配置
  saveConfig(params) {
    return proxyApi.postMessage({
      task: TaskEventType.CONFIG_UPDATE,
      data: params
    })
  },
  // 获取配置
  getConfig() {
    return proxyApi.postMessage({
      task: TaskEventType.CONFIG_GET
    })
  },
  clearConfig() {
    return proxyApi.postMessage({
      task: TaskEventType.CONFIG_CLEAR
    })
  },
};
export default config;
