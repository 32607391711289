import {proxyApi} from "../workers/ProxyApi";
import {TaskEventType} from "../workers/WorkerEventType"; // 根据需求是否导入qs模块

const edges = {
    getEdges(source=undefined, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal ? TaskEventType.EDGE_LOCAL_GET : TaskEventType.EDGE_CLOUD_GET,
            data: {
                source
            }
        })
    },
    updateEdge(params, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal ? TaskEventType.EDGE_LOCAL_UPDATE : TaskEventType.EDGE_CLOUD_UPDATE,
            data: params
        })
    },
    addEdge(params, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal ? TaskEventType.EDGE_LOCAL_ADD : TaskEventType.EDGE_CLOUD_ADD,
            data: params
        })
    },
    deleteEdge(params, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal ? TaskEventType.EDGE_LOCAL_DELETE : TaskEventType.EDGE_CLOUD_DELETE,
            data: params
        })
    },
    getNodes(params, isLocal = false) {
        return proxyApi.postMessage({
            task: isLocal ? TaskEventType.EDGE_LOCAL_NODE : TaskEventType.EDGE_CLOUD_NODE,
            data: params
        })
    },
    getAll() {
        return proxyApi.postMessage({
            task: 'edge.cloud.all',
        })
    }
}

export default edges;
