import { proxyApi } from "../workers/ProxyApi";
import { TaskEventType } from "../workers/WorkerEventType";
const groups = {
    getAll(isLocal) {
        return proxyApi.postMessage({
            task: "group.get.all",
            data: {
                isLocal,
            },
        });
    },
    saveGroupItem(params, isLocal) {
        return proxyApi.postMessage({
            task: "group.save.item",
            data: {
                isLocal,
                info: params,
            },
        });
    },
    saveGroup(params, type, isLocal) {
        return proxyApi.postMessage({
            task: "group.save",
            data: {
                isLocal,
                type: type,
                info: params,
            },
        });
    },
    deleteGroupItem(params, isLocal) {
        return proxyApi.postMessage({
            task: "group.delete.item",
            data: {
                isLocal,
                ...params,
            },
        });
    },
    deleteGroup(groupId, isLocal) {
        return proxyApi.postMessage({
            task: "group.delete",
            data: {
                isLocal,
                groupId,
            },
        });
    },
    getInfoList(ids, isLocal) {
        return proxyApi.postMessage({
            task: isLocal ? "doc.get.ids" : "cloud.get.ids",
            data: {
                ids,
            },
        });
    },
};

export default groups;
