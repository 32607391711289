/**
 * support模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const support = {
  // 获取最新版本
  latest(appId) {
    //需要本地化处理，就不用每次服务端获取数据了
    return axios.get(`${base.baseURL}/Support/Latest?appId=${appId}`);
  },

  // 上传app版本信息 --后台使用
  uploadAppFile(params) {
    return axios.post(
      `${base.baseURL}/Support/UploadAppFile/6TOIQ4RJQUT9XE4DJRCC`,
      params
    );
  },
};
export default support;
