/**
 * user模块接口列表
 */
import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const user = {
    // 收藏列表
    favoriteList(articleId) {
        return axios.get(`${base.baseURL}/api/User/FavoriteList`);
    },
    // 新增收藏
    addFavorite(params) {
        return axios.post(`${base.baseURL}/api/User/AddFavorite`, qs.stringify(params));
    },
    // 移除收藏
    removeFavorite(params) {
        return axios.post(`${base.baseURL}/api/User/RemoveFavorite`, qs.stringify(params));
    },
}
export default user;
