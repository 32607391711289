let p = {};
let doc = typeof document !== "undefined" ? document : p;
let win = typeof window !== "undefined" ? window : p;

HTMLElement.prototype.offset = function () {
  var box = this.getBoundingClientRect();
  var body = doc.body;
  var clientTop = this.clientTop || body.clientTop || 0;
  var clientLeft = this.clientLeft || body.clientLeft || 0;
  var scrollTop = body.scrollTop;
  var scrollLeft = body.scrollLeft;
  let left = box.left + scrollLeft - clientLeft;
  let right = left + this.offsetWidth;
  let top = box.top + scrollTop - clientTop;
  let bottom = top + this.offsetHeight;
  return {
    top: top,
    left: left,
    right: right,
    width: this.outerWidth(),
    height: this.outerHeight(),
    bottom: bottom,
  };
};
HTMLElement.prototype.outerHeight = function outerHeight(includeMargins) {
  if (includeMargins) {
    var styles = this.styles();
    return (
      this.offsetHeight +
      parseFloat(styles.getPropertyValue("margin-top")) +
      parseFloat(styles.getPropertyValue("margin-bottom"))
    );
  }
  return this.offsetHeight;
};
HTMLElement.prototype.outerWidth = function outerWidth(includeMargins) {
  if (includeMargins) {
    // eslint-disable-next-line
    var styles = this.styles();
    return (
      this.offsetWidth +
      parseFloat(styles.getPropertyValue("margin-right")) +
      parseFloat(styles.getPropertyValue("margin-left"))
    );
  }
  return this.offsetWidth;
};
HTMLElement.prototype.styles = function () {
  return win.getComputedStyle(this, null);
};
HTMLElement.prototype.hasClass = function (className) {
  return this.classList.contains(className);
};
HTMLElement.prototype.parent = function (selector, self = false) {
  let parent = self ? this : this.parentNode;
  if (selector) {
    while (parent) {
      if (parent.hasClass(selector)) {
        break;
      } else {
        parent = parent.parentNode;
      }
    }
    return parent;
  }
  return null;
};

String.prototype.isDark = function () {
  return this.valueOf().startsWith("dark");
};

String.prototype.isTrue = function () {
  return this.valueOf() === "true";
};
String.prototype.isFalse = function () {
  return this.valueOf() === "false";
};

Boolean.prototype.isTrue = function () {
  return !!this.valueOf();
};
Boolean.prototype.isFalse = function () {
  return !this.valueOf();
};
