/**
 * account模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import {http} from "./http"; // 导入http中创建的axios实例

export const account = {
  // 微信登录
  webWechatLogin(params) {
    return http.post(`${base.baseURL}/api/Account/WebWechatLogin`, params);
  },
  // 绑定/重新绑定微信号
  bindWebWechat(params) {
    return http.post(`${base.baseURL}/api/Account/BindWebWechat`, params);
  },
  // 绑定手机号
  bindMobile(params) {
    return http.post(`${base.baseURL}/api/Account/BindMobile`, params);
  },
  // 发送短信(绑定手机号用)
  sendBindMobileSms(params) {
    return http.post(`${base.baseURL}/api/Account/SendBindMobileSms`, params);
  },
  // 获取验证码
  // 接口返回JPEG文件的二进制流，设置请求数据类型：responseType:'arraybuffer'
  captcha(mobile) {
    return http.get(`${base.baseURL}/api/Account/Captcha?mobile=${mobile}`, {
      responseType: "arraybuffer",
    });
  },
  // 手机登陆
  mobileLogin(params) {
    return http.post(`${base.baseURL}/api/Account/MobileLogin`, params);
  },
  // 获取短信验证码
  sendSms(params) {
    return http.post(`${base.baseURL}/api/Account/SendSms`, params);
  },

  // 发送短信(登陆用)(滑动验证)
  sendAfsSms(params) {
    return http.post(`${base.baseURL}/api/Account/SendAfsSms`, params);
  },
  //获取用户信息
  getUserInfo() {
    return http.get(`${base.baseURL}/api/Account/AccountInfo`);
  },

  // 注销用户(删除用户账户和内容信息)
  logOff() {
    return http.post(`${base.baseURL}/api/Account/LogOff`);
  },
};
