
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
const message = {
    getMessage() {
        return axios.get(`${base.baseURL}/api/Message/Message`);
    },
    // PageNo PageSize
    getList(data) {
        return axios.get(`${base.baseURL}/api/Message/List`, data);
    },
    unreadCount() {
        return axios.get(`${base.baseURL}/api/Message/UnReadCount`);
    },
    // messageId
    read(data) {
        return axios.get(`${base.baseURL}/api/Message/Read`, data);
    },
    // messageId
    readAll() {
        return axios.get(`${base.baseURL}/api/Message/ReadAll`);
    },

}
export default message;