/**
 * upload模块接口列表
 */
import {proxyApi} from "../workers/ProxyApi";
import {TaskEventType} from "../workers/WorkerEventType";
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const urlReg = /^(http|https):\/\//;
const upload = {
    createOssSignature(fileName) {
        return axios.get(`${base.baseURL}/api/Upload/CreateOssSignature?fileName=${fileName}`);
    },
    loadImages(el) {
        if (!el || !el.querySelectorAll) return;
        let p = el.querySelectorAll('img[data-src]:not([src]):not([data-loaded])')
        p.forEach(el => {
            void this.loadImage(el);
        })
    },
    loadImage(el) {
        el.setAttribute('data-loaded', true);
        let dataSrc = el.getAttribute('data-src');
        let isLocal = true;
        if (urlReg.test(dataSrc)) {
            isLocal = false;
        }
        return proxyApi.postMessage({
            task: TaskEventType.FILE_GET,
            data: {
                url: dataSrc,
                isLocal
            }
        }).then(res => {
            if (res) {
                el.src = window.URL.createObjectURL(res);
            }
        })
    },
    uploadFile(file, isLocal, onProgress) {
        if (typeof isLocal === "function") {
            onProgress = isLocal;
            isLocal = false;
        }
        return proxyApi.postMessage({
            task: TaskEventType.FILE_UPLOAD,
            data: {
                file: file,
                isLocal
            },
            onProgress
        }).then(async res => {
            if (res.blob instanceof Blob) {
                res.localUrl = window.URL.createObjectURL(res.blob);
            }
            return res;
        })
    },
    blobToBase64(file) { //blob转base64
        return new Promise((resolve => {
            let reader = new window.FileReader()
            // 使用readAsArrayBuffer读取文件, result属性中将包含一个 ArrayBuffer 对象以表示所读取文件的数据
            reader.readAsArrayBuffer(file)
            reader.onload = function (e) {
                const result = e.target.result
                const contentType = file.type
                // 生成blob图片,需要参数(字节数组, 文件类型)
                const blob = new Blob([result], { type: contentType })
                // 使用 Blob 创建一个指向类型化数组的URL, URL.createObjectURL是new Blob文件的方法,可以生成一个普通的url,可以直接使用,比如用在img.src上
                const url = window.URL.createObjectURL(blob)
                resolve(url);
            }
        }))

    }
}
export default upload;
