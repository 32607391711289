/**
 * thought模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const thought = {

    // 思考存量列表
    topicList() {
        return axios.get(`${base.baseURL}/api/Thought/TopicList`);
    },

    // 保存存量信息
    saveThoughtTopic(params) {
        return axios.post(
            `${base.baseURL}/api/Thought/SaveThoughtTopic`,
            params
        );
    },

    // 移除思考存量信息
    removeTip(topicId) {
        return http.post(`${base.baseURL}/api/Thought/RemoveTopic/${topicId}`);
    },

    // 存量模型列表
    topicModelList(topicId) {
        return axios.get(`${base.baseURL}/api/Thought/TopicModelList/${topicId}`);
    },

    // 保存模型信息
    saveThoughtModel(params) {
        return axios.post(
            `${base.baseURL}/api/Thought/SaveThoughtModel`,
            params
        );
    },

    // 移除模型
    removeTopicModel(modelId) {
        return http.post(`${base.baseURL}/api/Thought/RemoveTopicModel/${modelId}`);
    },

};
export default thought;
