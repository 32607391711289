/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import { router } from "../router";
import store from "../store/index";
import CancelRequest from "./CancelRequest.js";
import { ElMessage } from "element-plus";
/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
var timeOut = null;
const tip = (msg) => {
  if (timeOut) {
    clearTimeout(timeOut);
  }
  timeOut = setTimeout(() => {
    ElMessage({
      message: msg,
      type: "error",
      grouping: true,
    });
  }, 500);
};

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: "/login",
    query: {
      redirect: router.currentRoute.fullPath,
    },
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (code, other) => {
  // 状态码判断
  switch (code) {
    // 401: 未登录状态，跳转登录页
    case 401:
      store.commit("delToken");
      store.commit("delToken");
      setTimeout(() => {
        toLogin();
      }, 500);
      break;
    default:
      tip(other);
  }
};

// 实例化取消请求对象
let cancelRequest = new CancelRequest();

// 创建axios实例
var instance = axios.create({ timeout: 1000 * 60 });
// 设置post请求头
instance.defaults.headers.post["Content-Type"] =
  "application/problem+json; charset=utf-8";
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作
    const token =
      store.state.mindmap_token || localStorage.getItem("mindmap_token");
    token && (config.headers.mindmap_token = token);
    config.headers.mindmap_device = store.state.mindmap_device;

    // 增加时间戳
    // config.date = parseInt(+new Date() / 100);
    // 检查之前是否存在相同的请求，如果存在则取消。
    cancelRequest.cancelReq(config);
    // 记录当前请求
    cancelRequest.addCancelReqKey(config, axios.CancelToken);

    return config;
  },
  (error) => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    // 移除成功请求记录
    cancelRequest.removeRequestKey(res.config);
    if (res.data.code == 401) {
      errorHandle(res.data.code, res.data.message);
    }
    return res.status === 200 ? Promise.resolve(res) : Promise.reject(res);
  },
  // 请求失败
  (error) => {
    // 移除失败的请求记录
    cancelRequest.removeRequestKey(error.config || {});
    // if (axios.isCancel(error)) {
    //     console.log('重复请求信息：' + error.message);
    // }
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.data.code, response.data.message);
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        store.commit("changeNetwork", false);
      } else {
        return Promise.reject(error);
      }
    }
  }
);

export default instance;
