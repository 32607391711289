export function transElIconName(iconName) {
  return "i" + iconName.replace(/[A-Z]/g, (match) => "-" + match.toLowerCase());
}
function detectTouch() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}

export const Platform = {
  isMobile:
      /(phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|webOS|blackberry|opera mini|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone)/i.test(navigator.userAgent.toLowerCase())
     && detectTouch(),
  // isMobile:
  //   navigator.userAgent.match(
  //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|webOS|BlackBerry|Opera Mini|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  //   ) !== null && detectTouch(),
  isMobile2:
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|webOS|BlackBerry|Opera Mini|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ) !== null,
};

console.log('--navigator-', navigator.userAgent, Platform.isMobile);


export const base64ToUint8array = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}