/**
 * historyArticle模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
import qs from "qs";
import {proxyApi} from "../workers/ProxyApi";
import {TaskEventType} from "../workers/WorkerEventType"; // 根据需求是否导入qs模块

const historyArticle = {
  // 段落历史记录
  paragraphHistory(articleId, pageIndex) {
    return axios.get(
      `${base.baseURL}/api/HistoryArticle/ParagraphHistory/${articleId}?pageIndex=${pageIndex}`
    );
  },

  // 段落历史记录
  paragraphHistoryByPage(articleId, page) {
    return proxyApi.postMessage({
      task: articleId.startsWith('local-')? TaskEventType.DOC_MD_PARAGRAPH:TaskEventType.CLOUD_MD_PARAGRAPH,
      data: {
        articleId,
        page
      }
    })
  },
  // 设置匿名分享开关
  paragraphHistoryToggle(articleId) {
    return axios.post(
      `${base.baseURL}/api/HistoryArticle/ParagraphHistoryToggle/${articleId}`
    );
  },

  // 获取匿名分享开关
  queryParagraphHistoryToggle(articleId) {
    return axios.get(
      `${base.baseURL}/api/HistoryArticle/QueryParagraphHistoryToggle/${articleId}`
    );
  },
};
export default historyArticle;
