

export const TaskEventType = {
    READY: 'ready',

    CLOUD_TREE_SEARCH: 'cloud.tree.search',
    DOC_TREE_SEARCH: 'doc.tree.search',

    DOC_TREE_EXPAND: 'doc.tree.expand',
    CLOUD_TREE_EXPAND: 'cloud.tree.expand',

    DOC_TREE_PARENT: 'doc.tree.parent',
    DOC_TREE_CHILDREN: 'doc.tree.children',
    DOC_TREE_UPDATE: 'doc.tree.update',
    DOC_TREE_RENAME: 'doc.tree.rename',
    DOC_TREE_DELETE: 'doc.tree.delete',
    DOC_TREE_CLONE: 'doc.tree.clone',
    DOC_TREE_TYPE: 'doc.tree.type',
    DOC_TREE_NODES: 'doc.get.nodes',

    CLOUD_TREE_PARENT: 'cloud.tree.parent',
    CLOUD_TREE_CHILDREN: 'cloud.tree.children',
    CLOUD_TREE_UPDATE: 'cloud.tree.update',
    CLOUD_TREE_RENAME: 'cloud.tree.rename',
    CLOUD_TREE_DELETE: 'cloud.tree.delete',
    CLOUD_TREE_CLONE: 'cloud.tree.clone',
    CLOUD_TREE_TYPE: 'cloud.tree.type',
    CLOUD_GET_CHILDREN: 'cloud.get.children',
    CLOUD_TREE_NODES: 'cloud.get.nodes',

    DOC_MD_UPDATE: 'doc.md.update',
    DOC_MD_GET: 'doc.md.get',
    DOC_MD_PARAGRAPH: 'doc.md.paragraph',
    DOC_GET_CHILDREN: 'doc.get.children',
    DOC_GET_HISTORY: 'doc.get.history',
    CLOUD_GET_HISTORY: 'cloud.get.history',
    DOC_GET_HISTORY_DETAIL: 'doc.get.history.detail',
    CLOUD_GET_HISTORY_DETAIL: 'cloud.get.history.detail',
    DOC_GET_HISTORY_RECOVER: 'doc.get.history.recover',
    CLOUD_GET_HISTORY_RECOVER: 'cloud.get.history.recover',

    CLOUD_MD_UPDATE: 'cloud.md.update',
    CLOUD_MD_GET: 'cloud.md.get',
    CLOUD_MD_PARAGRAPH: 'cloud.md.paragraph',

    DOC_TREE_DELETE_GET: 'doc.tree.delete.get',
    DOC_TREE_DELETE_RESTORE: 'doc.tree.delete.restore',
    DOC_TREE_DELETE_FOREVER: 'doc.tree.delete.forever',

    CLOUD_TREE_DELETE_GET: 'cloud.tree.delete.get',
    CLOUD_TREE_DELETE_RESTORE: 'cloud.tree.delete.restore',
    CLOUD_TREE_DELETE_FOREVER: 'cloud.tree.delete.forever',

    DOC_TREE_FULL: 'doc.tree.full',
    CLOUD_TREE_FULL: 'cloud.tree.full',


    FILE_UPLOAD: 'file.upload',
    FILE_GET: 'file.get',

    EDGE_LOCAL_GET: 'edge.local.get',
    EDGE_LOCAL_UPDATE: 'edge.local.update',
    EDGE_LOCAL_DELETE: 'edge.local.remove',
    EDGE_LOCAL_ADD: 'edge.local.add',
    EDGE_LOCAL_NODE: 'edge.local.node',
    EDGE_CLOUD_GET: 'edge.cloud.get',
    EDGE_CLOUD_UPDATE: 'edge.cloud.update',
    EDGE_CLOUD_DELETE: 'edge.cloud.remove',
    EDGE_CLOUD_ADD: 'edge.cloud.add',
    EDGE_CLOUD_NODE: 'edge.cloud.node',

    CONFIG_GET: 'config.get',
    CONFIG_CLEAR: 'config.clear',
    CONFIG_UPDATE: 'config.update',
    CONFIG_UPDATE_FONT_LIST: 'config.update.fontList',
    CONFIG_GET_FONT_LIST: 'config.get.fontList',
    CONFIG_REMOVE_FONT_LIST: 'config.remove.fontList'
}
