<template>
  <el-dialog class="update-dialog"
             v-model="dialogVisible"
             :show-close="!forceUpdate"
             :close-on-click-modal="!forceUpdate"
             :title="`更新提醒（${newVersion}`">
    <div class="update-inner" v-if="updateState===1">
      <ul>
        <li v-for="(item, index) in updateText" :key="index+'-s'">
          {{ item }}
        </li>
      </ul>
      <div class="update-state d-end">
        <el-button v-if="!forceUpdate"
                   class="close"
                   @click="onCancel">取消
        </el-button>
        <el-button type="primary"
                   @click="onUpdate">立即更新
        </el-button>
      </div>
    </div>
    <div class="update-inner" v-else-if="updateState===2">
      <el-progress status="success"
                   :text-inside="true"
                   :stroke-width="20"
                   :percentage="percentage"
                   :width="strokeWidth"
                   :show-text="true"/>
      <div class="updating-text">正在更新......</div>
    </div>
    <div class="update-inner" v-else-if="updateState===3">
      <div class="update-finished-text">更新完成</div>
      <div class="update-state d-end">
        <el-button v-if="!forceUpdate"
                   class="close"
                   @click="onCancel">下次启动更新
        </el-button>
        <el-button type="primary"
                   @click="onQuit">立即重启
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const {ipcRenderer} =  process.env.VUE_APP_PLATFORM === 'electron'? window.require('electron') : {}
export default {
  data() {
    return {
      dialogVisible: false,
      percentage: 0,
      strokeWidth: 400,
      newVersion: '1.0.0',
      updateState: 1, //0检测更新 1有更新 2正在更新 3更新完成 4没有更新
      updateText: ["1，更新内容1", "2，更新内容2", "3，更新内容3"],
      forceUpdate: false
    }
  },
  mounted() {
    //接收主进程版本更新消息
    if (ipcRenderer) {
      ipcRenderer.on("message", (event, arg) => {
        if ("update-available" === arg.name) {
          this.dialogVisible = true;
          this.newVersion = arg.message.version;
          this.forceUpdate = arg.message.forceUpdate;
        } else if ("download-progress" === arg.name) {
          this.percentage = Math.round(parseFloat(arg.message.percent));
        } else if ("error" === arg.name) {
          this.dialogVisible = false;
        } else if ('update-downloaded' === arg.name) {
          if (this.forceUpdate) {
            this.sendIpc('updateSuccess')
          } else {
            this.updateState = 3;
          }
        }
      });
    }
    //5秒后开始检测新版本
    setTimeout(() => {
      this.sendIpc("checkForUpdate");
    }, 2000)

    // 间隔1小时检测一次
    //   let interval = window.setInterval(() => {
    //     ipcRenderer.send("checkForUpdate");
    //   }, 60 * 60 * 1000);
  },
  methods: {
    onUpdate() {
      this.sendIpc("downloadUpdate");
      this.updateState = 2;
    },
    onCancel() {
      this.dialogVisible = false;
      this.updateState = 0;
    },
    sendIpc(type) {
      ipcRenderer && ipcRenderer.send(type);
    },
    onQuit() {
      this.sendIpc('updateSuccess')
    }
  }
}
</script>
