/**
 * userLog模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const userLog = {
  // 获取用户上报日志列表
  logList(apiKey, mobile, pageNo) {
    return axios.get(
      `${base.baseURL}/api/UserLog/LogList?ApiKey=${apiKey}&Mobile=${mobile}&PageNo=${pageNo}`
    );
  },
};
export default userLog;
