<template>
  <div class="loading-page d-center d-column">
    <!--    <svg-icon icon-class="logo-1" class-name="loading-logo"></svg-icon>-->
    <svg-icon icon-class="new-logo3" class="loading-logo"></svg-icon>
    <!-- <svg-icon icon-class="new-logo4" class="loading-logo2"></svg-icon> -->
    <progress-bar :progress="progress" />
    <div class="loading-info">热爱生活&nbsp;&nbsp;&nbsp;改变世界</div>
    <el-button
      v-if="loginState === 99"
      link
      class="error-button"
      type="info"
      @click="() => this.$emit('reload')"
    >
      网络错误，请点击重试
    </el-button>
  </div>
</template>

<script>
import ProgressBar from "../components/widget/ProgressBar";

export default {
  name: "LoadingPage",
  components: { ProgressBar },
  props: {
    loginState: { type: Number, default: 0 },
    progress: {
      // 当前进度
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.error-button {
  margin-top: 10px;
  border: 1px solid #999 !important;
  padding: 8px 16px !important;

  &:hover {
    border-color: var(--el-button-hover-link-text-color) !important;
  }
  &:active {
    background-color: #393939 !important;
  }
}
</style>
