/**
 * other模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const other = {
  chatgpt(params) {
    return axios.post(
      `https://chat-gpt.moxingshu.com.cn/v1/completion`,
      params
    );
  },
  chatgptV2(params) {
    return axios.post(`https://chat-gpt.moxingshu.com.cn/v2/stream`, params);
  },
};
export default other;
